/*
  Fertighaus Helpers – keep as agnostic as possible
*/

:root {
  --base-unit: 8px;

  --border-radius-medium: 8px;
  --border-radius-small: 4px;

  --card-box-shadow: 0 2px 50px 0 rgba(59, 71, 104, 0.05);
  --action-card-box-shadow: 0 5px 10px -1px rgba(59, 71, 104, 0.05),
    0 2px 4px -3px #3b4768, 0 2px 40px 0 rgba(59, 71, 104, 0.05);
  --action-card-box-shadow-hover: 0 6px 8px -2px rgba(59, 71, 104, 0.3),
    0 2px 4px -3px #3b4768, 0 2px 40px 0 rgba(59, 71, 104, 0.05);
  --modal-shadow: 0px 15px 30px 0px rgba(59, 71, 104, 0.15);

  --navbar-height: 56px;
  --salesapp-navbar-height: 71px;
}

/*
  You're not supposed to ever have a DOM element with the class
  f-wrap inside another DOM elements with the class f-wrap
*/
.f-wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 1160px; /* 1128 plus a 16px padding on both sides */
  padding-left: calc(var(--base-unit) * 2);
  padding-right: calc(var(--base-unit) * 2);
  width: 100%;
}

/* To create full-width elements if they exist within a .f-wrap element */
.f-\!wrap {
  margin-left: calc(var(--base-unit) * -2);
  margin-right: calc(var(--base-unit) * -2);
}

/* Height */
.f-h-100 {
  height: 100%;
}

.f-h-auto {
  height: auto;
}

.f-mh-100 {
  min-height: 100%;
}

/* Width */
.f-w-100 {
  width: 100%;
}

/* Flex helpers */
.f-d-iflr {
  display: inline-flex;
  flex-direction: row;
}

.f-d-flr {
  display: flex;
  flex-direction: row;
}
.f-d-flrr {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 1199px) {
  .f-d-flr\:s {
    display: flex;
    flex-direction: row;
  }
  .f-d-flc\:s {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  .f-d-flr\:l {
    display: flex;
    flex-direction: row;
  }
  .f-d-flc\:l {
    display: flex;
    flex-direction: column;
  }
  .f-fl-al-ce\:l {
    align-items: center;
  }
}
.f-d-flc {
  display: flex;
  flex-direction: column;
}
.f-gr {
  flex-grow: 1;
}

.f-no-shrink {
  flex-shrink: 0;
}

.f-fl-wrap {
  flex-wrap: wrap;
}

.f-fl-al-ce {
  align-items: center;
}

.f-fl-al-en {
  align-items: flex-end;
}

.f-fl-al-st {
  align-items: flex-start;
}

.f-fl-as-st {
  align-self: flex-start;
}

.f-fl-as-en {
  align-self: flex-end;
}

.f-fl-jc-st {
  justify-content: flex-start;
}

.f-fl-jc-ce {
  justify-content: center;
}

.f-fl-jc-sb {
  justify-content: space-between;
}

.f-fl-jc-sa {
  justify-content: space-around;
}

.f-fl-jc-en {
  justify-content: flex-end;
}

.f-fl-basis-0 {
  flex-basis: 0;
}

.f-fl-expand {
  flex: 1 1 auto;
}

.f-fl-no {
  flex: 0 0 auto;
}

.f-fl-half {
  flex: 1 0 50%;
}

.f-fl-default {
  flex: 1;
}

.f-fl-dbl {
  flex: 2;
}

.f-fl-gap-005 {
  gap: calc(var(--base-unit) * 0.5);
}

.f-fl-gap-010 {
  gap: calc(var(--base-unit) * 1);
}

.f-fl-gap-020 {
  gap: calc(var(--base-unit) * 2);
}

.f-fl-gap-030 {
  gap: calc(var(--base-unit) * 3);
}

.f-fl-gap-040 {
  gap: calc(var(--base-unit) * 4);
}

.f-fl-gap-050 {
  gap: calc(var(--base-unit) * 5);
}

.f-fl-gap-080 {
  gap: calc(var(--base-unit) * 8);
}

/* Other helpers */
.f-as-st {
  align-self: start;
}

.f-d-bl {
  display: block;
}

.f-d-inbl {
  display: inline-block;
}

.f-pos-rel {
  position: relative;
}

.f-pos-ab {
  position: absolute;
}

.f-radius-inputs {
  border-radius: var(--border-radius-inputs);
}

.f-radius-cta {
  border-radius: var(--border-radius-cta);
}

.f-radius-card-large {
  border-radius: var(--border-radius-card-large);
}

.f-radius-card-medium {
  border-radius: var(--border-radius-card-medium);
}

.f-bo-none {
  border: none !important;
}

.f-radius-card-large-top {
  border-radius: var(--border-radius-card-large) var(--border-radius-card-large)
    0 0;
}

.f-radius-card-large-bottom {
  border-radius: 0 0 var(--border-radius-card-large)
    var(--border-radius-card-large);
}

.f-radius-card-medium-top {
  border-radius: var(--border-radius-card-medium)
    var(--border-radius-card-medium) 0 0;
}

.f-radius-card-medium-bottom {
  border-radius: 0 0 var(--border-radius-card-medium)
    var(--border-radius-card-medium);
}

.f-bo-round {
  border-radius: 100%;
}

.f-shadow {
  box-shadow: var(--card-box-shadow);
}

.f-shadow-strong {
  box-shadow: var(--action-card-box-shadow);
}

.f-shadow-action {
  box-shadow: var(--action-card-box-shadow);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .f-shadow-action:hover {
    box-shadow: var(--action-card-box-shadow-hover);
    transform: translateY(-1px);
  }
}

.f-svg-shadow {
  filter: drop-shadow(0px 0px 2px rgba(59, 71, 104, 0.5));
}

.f-no-select {
  user-select: none;
}

.f-hidden-autocomplete {
  position: absolute !important;
  left: -999999px;
}

.f-hidden-autocomplete * {
  user-select: none !important;
  pointer-events: none !important;
  position: absolute !important;
  left: -999999px;
}

.f-hidden-select {
  background: rgba(0, 0, 0, 0.001);
  border: rgba(0, 0, 0, 0.001);
  bottom: 0;
  color: rgba(0, 0, 0, 0.001);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.f-hidden-select option {
  color: #333;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.f-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media (max-width: 371px) {
  .f-hidden\:xs {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .f-hidden\:s {
    display: none !important;
  }

  .f-w-100\:s {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .f-hidden\:m {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .f-hidden\:l {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .f-fl-jc-st\:s {
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .f-fl-jc-en\:l {
    justify-content: flex-end;
  }
}

.f-visibility-hidden {
  visibility: hidden;
}

.f-hidden {
  display: none !important;
}

.f-overflow {
  overflow: hidden;
}

.f-cur-def {
  cursor: default;
}

.f-interactive {
  pointer-events: all;
}

.f-\!interactive {
  pointer-events: none;
}

.f-grayscale {
  filter: grayscale(1);
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

.capitalized {
  text-transform: capitalize;
}

.f-focus-outline-secondary *:focus-visible {
  outline: 2px solid var(--colour-secondary);
}

.isolate {
  isolation: isolate;
}
