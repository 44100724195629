.SearchResultsPage-loader {
  min-height: 100vh;
}

.SearchResultsFilters-overlay {
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 100;
}

.SearchResultsFilters-overlay.is-embedded {
  bottom: 0;
  top: 0;
}

.SearchResultsFilters-wrapper {
  flex-wrap: wrap;
  margin-left: -4px;
  margin-right: -4px;
  padding-bottom: 12px;
  padding-top: 4px;
  width: 100%;
}

[class^='CTA-'].SearchResultsFilters-button {
  border-width: 1px;
  border-color: var(--colour-neutral-200);
  color: var(--colour-neutral-200);
  flex: 0 1 auto;
  font-family: var(--base-font-family);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  min-width: 0;
}

[class^='CTA-'].SearchResultsFilters-button__with-icon {
  display: flex;
  align-items: center;
  height: fit-content;
  flex-shrink: 0;
}

[class^='CTA-'].SearchResultsFilters-button.is-active {
  border-color: var(--colour-black);
  box-shadow: inset 0 0 0 0.5px var(--colour-black);
  color: var(--colour-black);
}

[class^='CTA-'].SearchResultsFilters-button__with-icon.is-active {
  background: var(--colour-primary-600);
  border-color: var(--colour-primary-600);
  color: var(--colour-white);
}

[class^='CTA-'].SearchResultsFilters-button.has-panel-displayed {
  border-color: var(--colour-primary-600);
  box-shadow: inset 0 0 0 0.5px var(--colour-primary-600);
  color: var(--colour-primary-600);
}

[class^='CTA-'].SearchResultsFilters-button.VendorMap-link {
  margin-left: auto;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  [class^='CTA-'].SearchResultsFilters-button.VendorMap-link:hover {
    border-color: var(--colour-primary-600);
    color: var(--colour-primary-600);
  }
}

.SearchResultsFilters-container {
  z-index: 100;
}

@media (max-width: 767px) {
  .SearchResultsFilters-container {
    bottom: -16px;
    left: 0 !important;
    position: fixed;
    right: 0;
    top: -16px;
  }
}

@media (min-width: 768px) and (max-height: 767px) {
  .SearchResultsFilters-container {
    max-height: 420px;
  }
}

@media (min-width: 768px) and (min-height: 768px) {
  .SearchResultsFilters-container {
    max-height: 640px;
  }
}

@media (min-width: 768px) {
  .SearchResultsFilters-container {
    border-radius: var(--border-radius-card-large);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    width: 352px;
  }

  .SearchResultsFilters-container.is-large {
    height: calc(
      100vh - 183px
    ); /* Top navigation plus filters plus 16px margins */
    width: 512px;
  }
}

@media (max-width: 767px) {
  .SearchResultsFilters-container-top {
    flex: 0 0 auto;
  }
}

.SearchResultsFilters-container-content {
  flex: 1 1 auto;
  overflow: auto;
}

@media (min-width: 768px) {
  .SearchResultsFilters-container-content.is-location {
    overflow: visible;
  }
}

.SearchResultsFilters-container-bottom {
  align-items: center;
  flex: 0 0 auto;
  margin-top: auto;
}

@media (min-width: 768px) {
  .SearchResultsFilters-container-bottom.has-shadow {
    box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.08);
    margin-left: calc(var(--space-020) * -1);
    margin-right: calc(var(--space-020) * -1);
    padding-left: var(--space-020);
    padding-right: var(--space-020);
  }
}

@media (max-width: 767px) {
  .SearchResultsFilters-close {
    margin-left: auto;
  }
}

@media (max-width: 767px) {
  .SearchResultsFilters-cta {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .SearchResultsFilters-cta {
    margin-left: auto;
  }
}

.SearchResultsFilterWrapper + .SearchResultsFilterWrapper {
  border-top-style: solid;
  border-top-width: 1px;
  margin-top: var(--space-020);
  padding-top: var(--space-020);
}

.SearchResultsFilters-price-bars {
  align-items: flex-end;
  display: flex;
  height: 30px;
  margin: 0 20px -10px;
}

.SearchResultsFilters-price-bar {
  background-color: rgba(230, 230, 230, 0.7);
  flex: 1;
  transition: height 0.2s ease-out;
}

.SearchResultsFilters-price-bar.is-active {
  background-color: var(--colour-tertiary-200);
}

.SearchResults-sorting {
  margin-left: auto;
}

.SearchResults-items-group {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.SearchResults__wrapper {
  background: var(--search-result-background);
}

.SearchResults__wrapper.SearchResults__wrapper--ex-leads {
  background: transparent;
}

.SearchResults-initial-min-height {
  min-height: calc(100vh - var(--navbar-height));
}

.SearchResults-items {
  align-items: flex-start;
  margin: 0 calc(var(--base-unit) * -2);
}

.SearchResults-items-group .SearchResults-items {
  min-height: 0;
}

.SearchResults-item-skeleton {
  height: 100%;
  width: 100%;
}

.SearchResults-item-house {
  overflow: hidden;
  border-radius: var(--border-radius-card-medium);
}

@media (max-width: 359px) {
  .SearchResults-item-house {
    width: calc(100% - 32px);
  }
}

@media (min-width: 360px) and (max-width: 1055px) {
  .SearchResults-item-house {
    max-width: 360px;
    min-width: 320px;
    flex: 1 0 320px;
  }
}

@media (min-width: 1056px) {
  .SearchResults-item-house {
    flex: 1 0 100%;
    max-width: calc(33.33333% - 32px);
  }
}

.SearchResults-item-house.is-top-placement:before,
.SearchResults-item-vendor.is-top-placement:before {
  color: var(--colour-neutral-100);
  content: 'Gesponsert';
  font-size: 12px;
  left: 0;
  position: absolute;
  top: -20px;
}

.SearchResults-item-house.is-top-placement:after,
.SearchResults-item-vendor.is-top-placement:after {
  border: 2px solid currentColor;
  border-radius: var(--border-radius-card-medium);
  bottom: -2px;
  left: -2px;
  content: '';
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -2px;
  top: -2px;
}

.SearchResults-item-house.is-top-placement:nth-child(2):after {
  animation-delay: 0.5s;
}

.SearchResults-item-house.is-top-placement:nth-child(3):after {
  animation-delay: 0.6s;
}

@media (max-width: 703px) {
  .SearchResults-item-house.is-top-placement:not(.is-top-local-placement):nth-child(n
      + 2) {
    display: none;
  }
}

@media (min-width: 704px) and (max-width: 1055px) {
  .SearchResults-item-house.is-top-placement:not(.is-top-local-placement):nth-child(n
      + 3) {
    display: none;
  }
}

.SearchResults-item-house-image-wrapper {
  flex: 1 0 100%;
  pointer-events: none;
}

.SearchResults-item-house-image-wrapper,
.SearchResults-item-house-image {
  height: 100%;
  width: 100%;
}

.SliderArrow {
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 32px;
  margin-top: -16px;
  opacity: 0.8;
  outline: none;
  position: absolute;
  top: 50%;
  transition: opacity 0.2s ease, box-shadow 0.2s ease;
  width: 32px;
}

.SliderArrow.is-disabled {
  opacity: 0 !important;
  pointer-events: none;
}

.SliderArrow-prev {
  left: 16px;
}

.SliderArrow-next {
  right: 16px;
}

.Slider-bullets {
  bottom: 8px;
  box-sizing: content-box;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: var(--space-020) var(--space-020) 0;
  text-align: center;
  transform: translateX(-50%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.Slider-bullet {
  background-color: var(--colour-neutral-050);
  border: 0;
  display: inline-block;
  height: var(--space-010);
  width: var(--space-010);
  margin: var(--space-005);
  outline: none;
  padding: 0;
  position: relative;
  transition: transform 0.2s ease, left 0.2s ease;
  transform: scale(0.75);
}

.Slider-bullet.is-active {
  background-color: var(--colour-white);
  transform: scale(1);
}

.Slider-bullet.is-active-prev,
.Slider-bullet.is-active-next {
  transform: scale(1);
}

.SearchResults-item-house-vendor-logo {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  flex: 0 0 auto;
  object-fit: contain;
  margin: auto;
}

.SearchResults-item-house-CTA {
  background-color: var(--colour-award) !important;
}

.SearchResults-item-house-CTA.top-local-placement {
  background-color: var(--colour-success-400) !important;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .SearchResults-item-house-CTA:hover {
    background-color: var(--colour-primary-600) !important;
  }
}

.SearchResults-item-house-CTA.is-toggled {
  background-color: var(--colour-primary-600) !important;
}

.SearchResults-item-house-awards-item {
  max-height: 22px;
  max-width: 22px;
}

.SearchResults-item-house-awards {
  transition: opacity 0.4s ease;
}

.SearchResults-item-house-awards {
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 1px;
}

.SearchResults-item-house-awards.is-displaying {
  opacity: 1;
}

.SearchResults-item-house-CTA-award {
  animation: award 3s 1;
  animation-delay: 3s;
}

.SearchResults-item-house-CTA-award use {
  stroke-width: 1;
}

.SearchResults-item-house-CTA-down {
  animation: downArrow 3s 1;
  animation-delay: 3s;
  opacity: 0;
}

@keyframes downArrow {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  42% {
    transform: translateX(0);
  }
  43% {
    transform: translateX(2px);
  }
  46% {
    transform: translateX(-2px);
  }
  49% {
    transform: translateX(1px);
  }
  52% {
    transform: translateX(-1px);
  }
  53% {
    transform: translateX(0);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes award {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.SearchResults-header {
  width: 100%;
}

@media (max-width: 359px) {
  .SearchResults-item-vendor {
    width: calc(100% - 32px);
  }
}

@media (min-width: 360px) and (max-width: 1055px) {
  .SearchResults-item-vendor {
    max-width: 360px;
    min-width: 320px;
    flex: 1 0 320px;
  }
}

@media (min-width: 1056px) {
  .SearchResults-item-vendor {
    flex: 1 0 100%;
    max-width: calc(33.33333% - 32px);
  }
}

.SearchResults-item-vendor.is-top-placement:before,
.SearchResultsTile-item.is-top-placement:before {
  color: var(--colour-neutral-100);
  content: 'Gesponsert';
  font-size: 12px;
  left: 0;
  position: absolute;
  top: -20px;
}

.SearchResults-top-placement-tag {
  left: 16px;
  display: inline-flex;
  pointer-events: none;
  top: 16px;
  border-radius: var(--border-radius-card-large);
}

.SearchResults-chat-app-button {
  bottom: 100px;
  right: 16px;
  display: inline-flex;
  pointer-events: none;
  border-radius: var(--border-radius-card-large);
}

.SearchResults-logo-local-office-wrapper {
  bottom: 100%;
  width: 100%;
}

.SearchResults-item-vendor-image-wrapper {
  border-radius: var(--border-radius-card-medium)
    var(--border-radius-card-medium) 0 0;
}

.SearchResults-item-vendor-image-wrapper::after {
  content: '';
  display: block;
  height: 119px;
  z-index: -1;
}

.SearchResults-item-vendor--no--reviews
  .SearchResults-item-vendor-image-wrapper::after {
  height: 82px;
}

.SearchResults-item-vendor-image {
  height: auto;
  pointer-events: none;
  width: 100%;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  a.SearchResults-vendor-name:hover {
    color: var(--colour-tertiary-400);
  }
}

.SearchResults-vendor-and-office-information {
  bottom: 0;
  pointer-events: none;
  width: 100%;
}

.SearchResults-vendor-logo-wrapper {
  width: 100px;
  height: 100px;
  background-color: white;
  flex-shrink: 0;
  border-radius: var(--border-radius-card-medium);
}

.SearchResults-vendor-logo {
  max-height: 48px;
  max-width: 84px;
}

.SearchResults-local-office-label {
  display: inline-flex;
  pointer-events: all;
}

.SearchResults-local-office-button {
  align-items: center;
  border-color: var(--colour-white);
  display: flex !important;
  font-size: 0.75rem !important;
  height: 24px;
  pointer-events: all;
}

.SearchResults-local-office-button:hover {
  box-shadow: var(--action-card-box-shadow-hover);
  transform: perspective(1px) scale(1.05);
  transition: transform 0.3s ease;
}

.SearchResults-vendor-and-office-information {
  --office-section-height: 101px;
}

.SearchResults-vendor-and-office-information.is-toggled {
  transform: translateY(calc(var(--office-section-height) * -1));
}

.SearchResultsVendor_review-section {
  --review-section-height-placeholder: 66px;
  padding: 8px 16px 16px 16px;
  visibility: visible;
  height: auto;
}

.SearchResultsVendor_review-section--inactive {
  display: flex;
  align-items: center;
  height: var(--review-section-height-placeholder);
}

.SearchResultsVendor_review-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 2px;
}

.SearchResultsVendor_avatar-wrapper {
  width: 40px;
  height: 40px;
  margin-right: var(--space-020);
  background: var(--colour-white);
  border-radius: 50%;
  overflow: hidden;
}

.SearchResultsVendor_avatar-wrapper svg {
  height: 40px;
  width: 40px;
}

.SearchResultsVendor_avatar-wrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
}

.SearchResultsVendor_rating-wrapper {
  display: flex;
  gap: 4px;
}

.ReviewRating_avg-rating {
  color: var(--colour-primary-600);
  font-family: var(--fancy-font-family);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 133%;
}
.ReviewRating_total-count {
  color: var(--colour-neutral-200);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: var(--base-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
}

.SearchResults-vendor-and-office-information.has-item-local-offices,
.SearchResults-vendor-and-office-information.has-item-local-offices--placeholder {
  bottom: calc(var(--office-section-height) * -1);
  transition: transform 0.2s ease;
}

.SearchResults-vendor-details-CTA-wrapper {
  width: 100%;
}

.SuccessTransitionBanner {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.SuccessTransitionBanner-title {
  align-items: flex-start;
}

.SearchResults-vendor-name-and-logo-section {
  display: flex;
  align-items: flex-end;
  height: 60px;
  gap: 16px;
  padding: 0 16px;
  margin-top: 8px;
}

.SearchResults-item-vendor--no--reviews
  .SearchResults-vendor-name-and-logo-section {
  height: 87px;
  padding: 16px;
  margin-top: 0;
}

.SearchResults-item-vendor {
  margin: 4px 0 8px 0;
}

.SearchResults-item-vendor--no--reviews .SearchResults-vendor-name {
  line-height: 1;
}

.SearchResults-vendor-details {
  min-height: 155px;
  pointer-events: all;
  width: 100%;
}

/* this is an exception -- find a way to put it our styleguide */
@media (min-width: 768px) {
  .SuccessTransitionBanner-title {
    align-items: center;
  }
}

@media (max-width: 480px) {
  .SuccessTransitionBanner-message .f-t-body {
    font-size: 12px;
    line-height: 16px;
  }
}

.SuccessTransitionBanner-icon-width {
  height: calc(var(--base-unit) * 2.5); /* 20px */
  width: calc(var(--base-unit) * 2.5); /* 20px */
}

.SuccessTransitionBanner-icon-width.is-small {
  height: calc(var(--base-unit) * 2.25); /* 18px */
  width: calc(var(--base-unit) * 2.25); /* 18px */
}

.SuccessTransitionBanner-icon-width use {
  stroke-width: 2;
}

@media all and (-ms-high-contrast: none) {
  .SuccessTransitionBanner-icon-width.is-16 use {
    stroke-width: 1.4;
  }

  .SuccessTransitionBanner-icon-width.is-small.is-16 use {
    stroke-width: 1.8;
  }
}
