.PageHeader-wrapper {
  position: relative;
  z-index: 1;
  background-color: var(--colour-primary-600);
  color: var(--colour-white);
  height: var(--navbar-height);
  transition: background-color 0.2s ease;
}

.PageHeader-menu .CTA-text-primary,
.PageHeader-menu-fullwidth .CTA-text-primary {
  color: var(--text-main-on-light);
}

.PageHeader-wrapper--fixed {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  max-width: none;
}

.has-modal .PageHeader-wrapper {
  background-color: var(--colour-primary-600);
}

.PageHeader-wrapper.is-sticky {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.PageHeader-wrapper.is-sticky + main {
  padding-top: var(--navbar-height);
}

.PageHeader-wrapper.is-toggled {
  z-index: 101;
}

.PageHeader-wrapper.is-floating:not(.is-toggled) {
  background-color: transparent;
}

.PageHeader-wrapper.is-floating.has-uniform-background {
  background-color: var(--colour-primary-600);
}

.PageHeader-wrapper.is-scrolled:not(.is-toggled),
.has-modal .PageHeader-wrapper.is-floating:not(.is-toggled) {
  background-color: var(--colour-primary-600);
}

.PageHeader-wrapper.is-sticky.is-floating + main {
  padding-top: 0;
}

.PageHeader-wrapper .PageHeader-logo-svg path {
  fill: var(--colour-white);
}

@media (max-width: 767px) {
  .PageHeader-bar {
    position: relative;
  }
}

.PageHeader-bar.is-full-width {
  width: 100%;
}

.PageHeader-logo-link {
  vertical-align: top;
  transition: opacity 0.2s ease;
}

@media (max-width: 339px) {
  .PageHeader-logo-link {
    overflow: hidden;
    width: 40px;
  }

  .PageHeader-logo-link.PageHeader-logo-link--compact {
    overflow: hidden;
    width: 35px;
  }
}

.PageHeader-nav:has(.PageHeader-nav-item) {
  display: flex;
  gap: 16px;
}

.PageHeader-nav-item {
  font-family: var(--fancy-font-family);
  font-size: 1rem;
}

.PageHeader-nav-item__with-menu {
  position: relative;
}

.PageHeader-nav-item__with-menu a {
  padding-right: var(--space-010);
}

.PageHeader-button.PageHeader-button {
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--text-main-on-dark);
  gap: 3px;
}

.PageHeader-menu-wrapper {
  vertical-align: middle;
}

@media (max-width: 1023px) {
  .PageHeader-menu-wrapper-burger {
    display: inline-block;
    vertical-align: middle;
  }

  .PageHeader-CTA-block {
    left: 16px;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    transition: opacity 0.2s ease;
  }

  @media (max-width: 360px) {
    .PageHeader-additional-button {
      display: none !important;
    }
  }
  @media (max-width: 385px) {
    .is-scrolled:not(.is-toggled):not(.is-logged-in)
      .PageHeader-additional-button {
      display: none !important;
    }
  }

  .is-scrolled:not(.is-toggled):not(.is-logged-in) .PageHeader-CTA-block {
    opacity: 1;
    pointer-events: all;
  }

  .is-scrolled:not(.is-toggled):not(.is-logged-in) .PageHeader-logo-link {
    opacity: 0;
    pointer-events: none;
  }
}

.PageHeader-menu-toggle-icon {
  position: absolute;
  right: var(--space-010);
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.2s ease;
}

.PageHeader-menu-toggle-icon-house-search {
  right: calc(var(--space-010) * -1);
}

.PageHeader-with-submenu {
  position: relative;
}

.PageHeader-sub-menu-header {
  font-family: var(--fancy-font-family);
  min-height: 1.4375em;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .PageHeader-button:hover.PageHeader-button:hover {
    background-color: var(--colour-primary-400);
    border-radius: var(--border-radius-cta);
  }
}

.PageHeader-menu-toggle-icon.is-open {
  transform: translateY(-50%) rotate(180deg);
}

@media (max-width: 1199px) {
  .PageHeader-navigation-row {
    display: none;
  }
}

@media (min-width: 1200px) {
  .PageHeader-navigation-row {
    display: inline-block;
  }

  .PageHeader-navigation {
    width: 100%;
  }

  .PageHeader-user-dropdown {
    transform: translateY(4px);
  }
}

@media (min-width: 1024px),
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
  /* This targets only retina screens on destkop. */
  .PageHeader-user-dropdown {
    transform: translateY(5px);
  }
}

.PageHeader-menu {
  display: block;
  overflow: auto;
  position: absolute;
  top: var(--navbar-height);
  z-index: 120; /* Search bar is 110 */
}

.PageHeader-menu__right {
  right: 0;
}

.PageHeader-menu__uplifted {
  transform: translateY(-3px);
}

@keyframes menu-reveal {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1199px) {
  .PageHeader-menu {
    height: calc(100vh - var(--navbar-height));
    position: fixed;
    right: 0;
    width: 100vw;
  }

  .PageHeader-menu.is-active {
    animation: menu-reveal 0.2s ease;
  }
}

@media (min-width: 1200px) {
  .PageHeader-menu {
    border-bottom-left-radius: var(--border-radius-card-large);
    border-bottom-right-radius: var(--border-radius-card-large);
    box-shadow: var(--modal-shadow);
    height: auto;
    width: 14rem;
  }
}

@media (max-width: 1023px) {
  .PageHeader-menu:after {
    -webkit-backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    content: '';
    left: -500px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 500px;
  }
}

.PageHeader-mobile-menu-icon path {
  stroke: var(--colour-white);
  stroke-width: 1.5;
  transition: stroke 0.2s ease;
}

.PageHeader-mobile-menu-icon.is-active path {
  stroke: var(--colour-tertiary-400);
}

.PageHeader-menu-mobile-item {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.PageHeader-menu-mobile-item:has(ul) a {
  padding-bottom: 0;
}

.PageHeader-menu-mobile-item:has(ul) {
  padding-bottom: 16px;
}

.PageHeader-menu-mobile-item + .PageHeader-menu-mobile-item {
  border-top-style: solid;
  border-top-width: 1px;
}

.PageHeader-menu-mobile-divider {
  height: 1px;
  background-color: var(--colour-neutral-050);
}

.PageHeader-menu-button {
  width: 100%;
  font-family: var(--base-font-family);
}

.PageHeader-menu-button-icon {
  float: right;
}

.PageHeader-menu-fullwidth {
  pointer-events: none;
  position: absolute;
  right: 0;
  top: var(--navbar-height);
  width: 100vw;
  z-index: 99;
}

.PageHeader-menu-fullwidth-content {
  pointer-events: all;
}

@media (min-width: 1024px) {
  .PageHeader-menu-fullwidth:after {
    -webkit-backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    height: 100vh;
    max-width: 100%;
    pointer-events: none;
    position: absolute;
    z-index: -1;
    width: 100vw;
  }
}

.PageHeader-nav-link {
  text-decoration: none;
}

@media (min-width: 768px) {
  .PageHeader-nav-link.is-active:not(.PageHeader-with-submenu.is-open) {
    position: relative;
  }
}

.PageHeader-nav-link:focus-visible,
.PageHeader-CTA-block:focus-visible,
.PageHeader-button:focus-visible {
  background-color: var(--colour-primary-400);
}

.is-scrolled .PageHeader-CTA-block:focus-visible {
  outline: 2px solid var(--colour-white);
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .PageHeader-nav-link:hover {
    text-decoration: none;
    background-color: var(--colour-primary-400);
  }
}

.PageHeader-CTA-block {
  background-color: transparent;
  border-color: var(--colour-white);
  color: var(--colour-white);
  border-width: 1px;
  margin-right: var(--space-010);
}

@media (max-width: 359px) {
  .PageHeader-CTA-block {
    padding-left: var(--space-010);
    padding-right: var(--space-010);
  }
}

.is-floating .PageHeader-CTA-block {
  border-color: var(--colour-white);
  color: var(--colour-white);
}

.is-scrolled .PageHeader-CTA-block {
  background-color: var(--colour-secondary);
  border-color: var(--colour-secondary);
  color: var(--colour-primary-600) !important;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .is-scrolled .PageHeader-CTA-block:hover {
    transform: translateY(-1px);
  }
}

@media (hover: hover) and (pointer: fine), (-ms-high-contrast: none) {
  .is-floating .PageHeader-CTA-block:hover,
  .PageHeader-CTA-block:hover {
    border-color: var(--colour-secondary);
    color: var(--colour-secondary);
  }
}

.PageHeader-CTA-block:active,
.PageHeader-CTA-block.is-active {
  border-color: var(--colour-primary-800);
  color: var(--colour-primary-800);
}

.PageHeader-button-icon {
  height: 18px;
  width: 18px;
  fill: transparent;
}

.PageHeader-button-label {
  font-weight: 400;
  font-family: var(--base-font-family);
  font-size: 10px;
  color: inherit;
}

.PageHeader-button.CTA-block-secondary {
  box-shadow: none;
}

@media (hover: hover) and (pointer: fine), not all {
  .PageHeader-button.CTA-block-secondary:hover {
    transform: unset;
  }
}

.PageHeader-additional-button {
  color: white;
}

.PageHeader-wrapper.PageHeader-wrapper.PageHeader-wrapper--with-rating {
  background-color: var(--colour-primary-600);
}

.PageHeader-wrapper--with-rating-light,
.PageHeader-wrapper--with-rating-light.is-scrolled:not(.is-toggled) {
  background-color: var(--colour-background-025);
}

.PageHeader-stars {
  color: var(--text-accent-on-light);
}

.no-scroll {
  position: fixed;
  inline-size: 100%;
  overflow-y: scroll;
}

.overflow-auto {
  overflow: auto;
}

@media (max-width: 767px) {
  .PageHeader-wrapper--compact {
    background-color: var(--colour-background-025);
    color: var(--colour-primary-600);
    display: flex;
  }

  .PageHeader-wrapper--checkout {
    background-color: var(--colour-primary-600);
    padding-top: 0;
  }

  .PageHeader-wrapper--with-rating-light.has-uniform-background.has-uniform-background {
    background-color: var(--colour-background-025);
  }
}

@media (min-width: 767px) {
  .PageHeader-step-count.PageHeader-step-count--navbar {
    display: none;
  }
  .PageHeader-wrapper--compact {
    padding: var(--base-unit) 0;
  }
  .PageHeader-wrapper--with-rating-light,
  .PageHeader-wrapper--with-rating-light.is-scrolled:not(.is-toggled) {
    background-color: var(--colour-primary-600);
  }
  .PageHeader-wrapper--with-rating-light .HLP-hero-rating-star--empty use {
    fill: var(--colour-primary-600);
  }
}

@media (max-width: 400px) {
  .PageHeader-stars {
    display: none;
  }
}

@media (min-width: 1200px) {
  .PageHeader-menu-wrapper-burger {
    display: none;
  }
}
